<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="ยูสเซอร์"
            label-for="account-username"
          >
            <b-form-input
              v-model="optionsLocal.phoneno"
              placeholder="ยูสเซอร์"
              name="username"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="ขื่อ"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.name"
              name="name"
              placeholder="Name"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="gauth && gauthQR">
        <b-col cols="12">
          <b-form-group
            label="กรุณาใช้ Google Authenticator แสกน"
            label-for="account-name"
          >
            <img :src="gauthQR">
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="saveUser"
          >
            บันทึก
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            ยกเลิก
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    // BImg,
    // BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    // BAlert,
    BCard,
    // BCardText,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    // BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(localStorage.getItem('userData') || '{}'),
      profileFile: null,
      gauth: false,
      gauthQR: null,
    }
  },
  async mounted() {
    try {
      const { data } = await this.$http.get('admin/session?details=1')
      if (data.gauth) {
        this.gauth = data.gauth
        this.gauthQR = data.gauthQR
      }
    } catch (err) {
      console.log()
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(localStorage.getItem('userData') || '{}')
    },
    saveUser() {
      let isError = false
      let errTitle = ''
      let errMsg = ''
      if (this.optionsLocal.phoneno.length <= 5) {
        isError = true
        errTitle = 'เกิดข้อผิดพลาดการเปลี่ยนยูสเซอร์'
        errMsg = 'กรุณากรอกข้อมูลรหัสผ่านให้ครบทุกช่อง'
      }
      if (isError) {
        this.$swal({
          title: errTitle,
          text: errMsg,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'ตกลง',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      this.$swal({
        title: 'เปลี่ยนรหัสผ่าน',
        text: 'ยืนยันที่จะเปลี่ยนรหัสผ่านหรือไม่',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่ ตกลง',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value)
          return this.$http.put('admin/change-self-password', { oldPassword: this.passwordValueOld, newPassword: this.newPasswordValue })
        return { data: { success: false } }
      }).then(({ data: result }) => {
        if (result && result.success) {
          this.$swal({
            title: 'เปลี่ยนรหัสผ่าน',
            text: 'เปลี่ยนรหัสผ่านเรียบร้อย',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          return
          // const withdrawItem = ctx.unsuccessItems[ctx.unsuccessItems.indexOf(data)]
          // withdrawItem.status_text = 'รออนุมัติการถอน'
          // withdrawItem.status = 'awaiting'
          // console.log('result', result)
        }
        throw new Error('')
      }).catch(err => {
        this.$swal({
          title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
          text: `${err} | กรุณาตรวจสอบรหัสผ่านของคุณอีกครั้ง`,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'ตกลง',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
