<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            label="รหัสผ่านเดิม"
            label-for="account-old-password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old-password"
                :type="passwordFieldTypeOld"
                placeholder="รหัสผ่านเดิม"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            label="รหัสผ่านใหม่"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="รหัสผ่านใหม่"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="ยืนยันรหัสผ่านใหม่"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="RetypePassword"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="ยืนยันรหัสผ่านใหม่"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="changePassword"
          >
            เปลี่ยนรหัสผ่าน
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    changePassword() {
      let isError = false
      let errTitle = ''
      let errMsg = ''
      if (this.passwordValueOld.length <= 0 || this.newPasswordValue.length <= 0 || this.RetypePassword.length <= 0) {
        isError = true
        errTitle = 'เกิดข้อผิดพลาดการเปลี่ยนรหัสผ่าน'
        errMsg = 'กรุณากรอกข้อมูลรหัสผ่านให้ครบทุกช่อง'
      }
      if (this.newPasswordValue.length < 8) {
        isError = true
        errTitle = 'เกิดข้อผิดพลาดการเปลี่ยนรหัสผ่าน'
        errMsg = 'รหัสผ่านใหม่ต้องมีความยาวอย่างน้อย 8 หลัก และมีตัวเลข ภาษาอังกฤษพิมพ์เล็ก ภาษาอังกฤษพิมพ์ใหญ่ อย่างน้อยอย่างละตัว'
      }
      if (this.newPasswordValue !== this.RetypePassword) {
        isError = true
        errTitle = 'เกิดข้อผิดพลาดการเปลี่ยนรหัสผ่าน'
        errMsg = 'รหัสผ่านใหม่ กับช่องยืนยันรหัสผ่านไม่ตรงกัน'
      }
      if (isError) {
        this.$swal({
          title: errTitle,
          text: errMsg,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'ตกลง',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      this.$swal({
        title: 'เปลี่ยนรหัสผ่าน',
        text: 'ยืนยันที่จะเปลี่ยนรหัสผ่านหรือไม่',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่ ตกลง',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value)
          return this.$http.put('admin/change-self-password', { oldPassword: this.passwordValueOld, newPassword: this.newPasswordValue })
        return { data: { success: false } }
      }).then(({ data: result }) => {
        if (result && result.success) {
          this.$swal({
            title: 'เปลี่ยนรหัสผ่าน',
            text: 'เปลี่ยนรหัสผ่านเรียบร้อย',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          return
          // const withdrawItem = ctx.unsuccessItems[ctx.unsuccessItems.indexOf(data)]
          // withdrawItem.status_text = 'รออนุมัติการถอน'
          // withdrawItem.status = 'awaiting'
          // console.log('result', result)
        }
        throw new Error('')
      }).catch(err => {
        this.$swal({
          title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
          text: `${err} | กรุณาตรวจสอบรหัสผ่านของคุณอีกครั้ง`,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'ตกลง',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
      })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
